import {IngestionMessage} from "../asset-service-api";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useHdValidator = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources, getMappedTrack} = useProjects(projectId!);

    const isProductHd = () => {
        const releaseInfo = project?.releaseInfo;

        switch (releaseInfo?.configuration) {
        case "eAlbum Audio + Booklet HD":
        case "eAlbum Audio HD":
        case "eAudio Stem Bundle HD":
        case "eFixedPlaylist Audio HD":
        case "eSingle Audio/Multi Track + Booklet HD":
        case "eSingle Audio/Multi Track HD":
        case "eSingle Audio/Single Track + Booklet HD":
        case "eSingle Audio/Single Track HD":
        case "eAlbum Audio (Sollos) HD":
        case "eSingle Audio/Single Track (Sollos) HD":
        case "eSingle Audio/Multi Track (Sollos) HD":
        case "eMix Bundle Album HD":
        case "eMix Bundle Album + Booklet HD":
        case "eMix Bundle Album + LP HD":
        case "eMix Bundle Single HD":
        case "eMix Bundle Single + Booklet HD":
            return true;
        default:
            return false;
        }
    };

    const isExplicit = () => {
        const releaseInfo = project?.releaseInfo;

        switch (releaseInfo?.parentalAdvisory) {
        case "Non-Applicable":
            return false;
        default:
            return true;
        }
    };
    const getHDValidationErrors = (): IngestionMessage[] => {
        const releaseInfo = project?.releaseInfo;
        const allowedChannels = 2;

        if (isProductHd()) {
            const errorMessages = getMappedResources().filter(x => (x.audioInfo?.bit_depth ?? 0) < 24).map(x => {
                return {
                    details: `Asset ${ x.filename }, assigned to Track ${ getMappedTrack(x)?.number }, Bit Depth (${ x.audioInfo?.bit_depth }) is less than the allowed minimum (24) for configuration ${ releaseInfo!.configuration }.`,
                    message: "Tracks on HD products must be at least 24 bit.",
                    messageType: "Error",
                    source: {
                        scope: "Track",
                        stage: "local",
                        target: getMappedTrack(x)?.number.toString(),
                        id: "7e7f7c03-5c8b-46bb-b62f-6b237a9d0f25",
                    },
                } as IngestionMessage;
            });

            errorMessages.push(...getMappedResources().filter(x => (x.audioInfo?.channels ?? 0) !== allowedChannels).map(x => {
                return {
                    message: `File has ${x.audioInfo?.channels ?? 0} audio channels. This product configuration requires ${allowedChannels} channels.`,
                    messageType: "Error",
                    source: {
                        scope: "Track",
                        stage: "local",
                        target: getMappedTrack(x)?.number.toString(),
                        id: "05377e7b-8936-4112-b45d-e362c46d2ff2",
                    },
                } as IngestionMessage;
            }));
            return errorMessages;
        } else return [];
    };
    return { getHDValidationErrors, isExplicit, isProductHd};

};
