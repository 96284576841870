import { Table } from "react-bootstrap";
import { ITrack } from "../../../MapData";
import { FormatDuration, FormatTitle } from "../../../../Formatting";
import { TrackBody } from "../../../TrackBody";
import { TrackGroupBody } from "../../../TrackGroupBody";
import { TrackStatus } from "../../../../StatusIcons/TrackStatusIcon";
import { useValidation } from "../../../../validation/validation";
import { useProjects, hasMultipleTrackGroups, hideTotalDuration } from "../../../../Behaviors/projects";
import { transformTrackGroups, transformTracks, ITrackView } from "../../../../Behaviors/trackView";
import { DdpPqDescriptorPacket, ResourceType } from "../../../../api";
import { useStore } from "../../../../State/zustandStore";

// Get the packet with the highest index number for each track
function filterPackets(packets: DdpPqDescriptorPacket[]) {
    if (!packets) {
        return [];
    }

    const maxIndexPackets = packets.reduce((acc, val) => {
        if (!acc.find(i => i.trackNumber === val.trackNumber)) { // If track number not seen yet, then add it
            acc.push(val);
        } else if (acc.find(i => i.trackNumber === val.trackNumber)?.indexNumber ?? 0 < val.indexNumber!) { // If index is greater than the previous track then remove and add
            acc = acc.filter(i => i.trackNumber !== val.trackNumber);
            acc.push(val);
        }
        return acc;
    }, [] as DdpPqDescriptorPacket[]);
    return maxIndexPackets;
}

interface Column<D> {
    accessor: keyof D,
    Header: string,
    minWidth?: string;
    rowSpan?: number
    colSpan?: number
    class?: string
    Formatter?: Function
}

export const DdpTrackList = () => {
    const {
        hasTrackValidationErrors,
        hasTrackValidationWarnings,
        getTrackValidationErrors,
        getTrackValidationWarnings,
    } = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { project, getTracks, getTrackGroups, getSelectedProjectResources } = useProjects(projectId!);
    const jobId = project?.jobId;
    const columns: Column<ITrack>[] = [
        {
            accessor: "number",
            Header: "Track",
            colSpan: 1,
            class: "iconic",
        },
        {
            accessor: "title",
            Header: "Title",
            class: "word-break full-width",
            colSpan: 4,
            Formatter: FormatTitle,
        },
        {
            accessor: "duration",
            Header: "Duration",
            class: "numeric",
            Formatter: FormatDuration,
        },
        {
            accessor: "isrc",
            Header: "ISRC",
            class: "numeric",
            colSpan: 3,
        },
    ];

    let items: ITrackView[] = [];
    const ddpPq = getSelectedProjectResources()?.find(r => r.resourceType === ResourceType.DdpPq);
    const pqDescriptors = ddpPq?.pqDescriptorPackets?.filter(p => p.trackNumber! > 0);
    const pqTracks = filterPackets(pqDescriptors!);

    const trackTitles = getSelectedProjectResources()?.find(r => r.resourceType === ResourceType.DdpText)?.trackTitles;
    const multipleTrackGroups = hasMultipleTrackGroups(project!);
    const hideDuration = hideTotalDuration(project!);
    const trackViews = multipleTrackGroups // AT-3499
        ? transformTrackGroups(getTrackGroups(), hideDuration)
        : transformTracks(getTracks());

    if ((!trackViews || trackViews.length === 0) && pqTracks) { // Don't have product data, but have PQ data
        items = pqTracks?.map(p => {
            const track: ITrackView = {
                number: p.trackNumber!,
                duration: 0,
                title: "",
                isrc: "",
                versionTitle: "",
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === p.trackNumber)?.title ?? `Track ${p.trackNumber}`,
                ddpTrackDuration: Number(p.durationMs) / 1000,
                subTracks: null,
            };
            return track;
        });
    } else { // Have product data
        items = trackViews.map((t) => {
            const track: ITrackView = {
                number: t.number,
                duration: t.duration!,
                title: t.title,
                isrc: t.isrc,
                versionTitle: t.versionTitle,
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === t.number)?.title ?? `Track ${t.number}`,
                ddpTrackDuration: Number((pqTracks?.find(p => t.number === p.trackNumber)?.durationMs)) / 1000,
                subTracks: t.subTracks,
            };

            if (!track.ddpTrackDuration) {
                track.ddpTrackDuration = 0;
            }

            if (!pqTracks?.find(p => p.trackNumber === t.number)) {
                track.ddpTrackTitle = "";
            }
            return track;
        });

        const trackNumbers = trackViews.map(t => t.number);

        pqDescriptors?.filter(p => !trackNumbers.includes(p.trackNumber!)).forEach(p => {
            const track: ITrackView = {
                number: p.trackNumber!,
                duration: 0,
                title: "",
                isrc: "",
                versionTitle: "",
                ddpTrackTitle: trackTitles?.find(tt => tt.trackNumber === p.trackNumber)?.title ?? `Track ${p.trackNumber}`,
                ddpTrackDuration: Number(p.durationMs) / 1000,
                subTracks: null,
            };

            items.push(track);
        });
    }
    return (
        <>
            <Table role={"list"}
                aria-labelledby="tracklist-heading"
                className={"product-track-list mb-0  "}
                aria-label="R2 Track List"
                size="sm">
                {
                    items.map(item => {
                        return (
                            <TrackBody key={item.number} track={item}>
                                <tr key={`${item.number}a`}>
                                    <td rowSpan={(item?.subTracks?.length ?? 2) + 1} className={"status-iconic"}>
                                        <TrackStatus Track={item} />
                                    </td>
                                    {columns.map(col => {
                                        return (
                                            <td key={col.accessor} rowSpan={col.rowSpan} colSpan={col.colSpan}
                                                className={col.class} style={{ minWidth: col.minWidth }}>
                                                {col.Formatter ? col.Formatter(item[col.accessor], item.versionTitle) : item[col.accessor]}
                                            </td>
                                        );
                                    },
                                    )}
                                    <td />
                                </tr>
                                { /* AT-3498 added to handle track groups */
                                    item.subTracks ?
                                        <TrackGroupBody key={`${item.number}sub`} subTracks={item.subTracks} />
                                        : null
                                }
                                {(item.ddpTrackTitle && item.ddpTrackTitle.length > 0) ?
                                    <tr key={`${item.number}cv`}>
                                        <td />
                                        <td style={{ textAlign: "left" }} colSpan={4}>
                                            {item.ddpTrackTitle}
                                        </td>
                                        <td style={{ textAlign: "left" }}> {FormatDuration(item.ddpTrackDuration)}</td>
                                        <td style={{ textAlign: "left" }} />
                                        <td />
                                    </tr>
                                    : null
                                }
                                {hasTrackValidationWarnings(jobId, item) || hasTrackValidationErrors(jobId, item)
                                    ? <tr key={`${item.number}warn`}>
                                        <td colSpan={9}>
                                            {getTrackValidationErrors(jobId, item).concat(getTrackValidationWarnings(jobId, item))[0].message}
                                        </td>
                                    </tr>
                                    : null
                                }
                            </TrackBody>
                        );
                    })
                }
                <tbody className={"filler-row"}>
                    <tr className={"filler-row"} />
                </tbody>
            </Table>
        </>
    );
};
